import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Transitions from 'vue2-transitions';
import { generalFunction } from './js/generalFunction.js';
import { EventBus } from '@/eventBus';

import DashboardPlugin from "./plugins/dashboard-plugin";

Vue.use(require('vue-cookies'));

Vue.use(Transitions);

Vue.use(DashboardPlugin);

Vue.config.productionTip = false;

Vue.prototype.generalFunction = generalFunction;
Vue.prototype.EventBus = EventBus;
Vue.prototype.Socket = io(process.env.VUE_APP_ENDPOINT_API);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
