<template>
    <v-app-bar :color="background"
               dark
               height="auto"
               :style="$vuetify.breakpoint.mdAndUp ? '' : 'padding: 0 !important;'"
               class="py-4 px-2 toolbar-content-padding-y-none"
               :class="{ 'border-bottom': !hasBg, 'border-bottom-dark': hasBg }"
               flat
    >
        <v-row>
            <v-col v-if="$vuetify.breakpoint.mobile" cols="1" class="px-0">
                <v-btn elevation="0"
                       :ripple="false"
                       :height="43"
                       class="font-weight-600 text-capitalize drawer-toggler rounded-sm"
                       :class="{ 'btn-dark-toggler-hover': !hasBg, 'btn-toggler-hover': hasBg, active: togglerActive }"
                       color="transparent"
                       style="justify-content: left;"
                       @click="drawerClose"
                >
                    <div class="drawer-toggler-inner">
                        <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
                        <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
                        <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
                    </div>
                </v-btn>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? '5' : '0'">
                <router-link :to="isAdmin ? '/' : '/login'">
                    <v-img src="@/assets/logo.png"
                           :width="$vuetify.breakpoint.mdAndUp ? '150px' : '150px'"
                           :class="!$vuetify.breakpoint.mdAndUp ? 'mt-1' : ''" />
                </router-link>
            </v-col>
            <v-col cols="6" class="text-right">
                <v-btn depressed
                       :ripple="false"
                       @click="logout()"
                       class="font-weight-600 ls-0 text-capitalize pe-0"
                       color="transparent"
                >
                    <v-icon class="mr-3">fas fa-user</v-icon>
                    <span :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                          :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''">
                        {{ $vuetify.breakpoint.smAndUp ? nomeLogin : '' }}
                    </span>
                </v-btn>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
import apiUtenti from "@/js/pages/utenti";
import { generalFunction } from "@/js/generalFunction.js";
export default {
    name: "appBar",
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: Boolean,
    },
    data() {
        return {
            token: '',
            drawer: false,
            togglerActive: false,
            nomeLogin: "",
        };
    },
    computed: {
        isAdmin() {
            return this.$store.state.isAdmin || "";
        }
    },
    methods: {
        drawerClose() {
            this.togglerActive = !this.togglerActive;
            this.$emit("drawer-toggle", this.togglerActive);
        },
        async loadData() {
            this.token = this.$cookies.get('tokengestionalestorello');
            if(this.token) {
                let utente = await apiUtenti.getOneFromToken(this.token);
                this.nomeLogin = utente ? utente["nome"] : '';
            } else this.nomeLogin = '';
        },
        async logout(){
            if(
                this.$cookies.get('tokengestionalestorello') &&
                confirm('Effettuare il logout?')
            ) {
                this.$cookies.remove('tokengestionalestorello');
                this.$store.state.token = '';
                this.$store.state.isAdmin = '';
                this.$store.state.idUtente = '';
                this.nomeLogin = '';
                await this.$router.push('/login');
            }
        }
    },
    async created() {
        await this.loadData();
        this.EventBus.$on("login", () => {
            this.loadData();
        });
    },
    watch: {
        toggleActive(val) {
            this.togglerActive = val;
        },
    }
};
</script>
