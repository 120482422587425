import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/variazioni/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/variazioni/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/variazioni/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}