<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Nuovo Prodotto</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogNew')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="nomeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="nome"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Descrizione</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Descrizione"
              autocomplete="off"
              v-model="descrizione"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea>
            
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Categoria</label
            >
            <v-combobox
              v-model="categoria"
              :items="categorie"
              :search-input.sync="searchCategoria"
              @keyup.enter="categoriaSelected"
              hide-selected
              hint="Seleziona o creane una"
              small-chips
              class="pt-0"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Nessun risultato per "<strong>{{
                        searchCategoria
                      }}</strong
                      >". Premi <kbd>invio</kbd> per crearla
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Crea</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiProdotti from "@/js/pages/prodotti";
import apiCategorie from "@/js/pages/categorie";
import {
  emailValidaRules,
  nomeRules,
  requiredRules,
} from "@/js/validationRules";

export default {
  name: "newProdotto",
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      nome: "",
      descrizione: "",
      formValid: false,
      emailRules: emailValidaRules,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      loading: false,
      categorie: [],
      categoria: undefined,
      searchCategoria: undefined,
    };
  },
  async created() {
    this.categorie = await apiCategorie.getAll();
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if(!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = {
            nome: this.nome,
            descrizione: this.descrizione,
            nomeCategoria: this.categoria || undefined
          };
          let newProdotto = await apiProdotti.insertNew(this.token, data);
          if (newProdotto) this.$emit("chiudiDialogNew");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
    async categoriaSelected() {
      if (this.categoria && !this.categorie.includes(this.categoria) && !this.loading) {
        this.loading = true;
        let id_categoria = await apiCategorie.insertNew(this.token, {
          nome: this.categoria,
        });
        if (id_categoria && id_categoria !== "categoriaEsistente") {
          this.categorie = await apiCategorie.getAll();
        } else alert("Impossibile creare, nome già esistente");
        this.loading = false;
      }
    },
  },
};
</script>
