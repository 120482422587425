<template>
  <v-dialog
    v-model="alertIsActive"
    max-width="290"
    @click:outside="closeAlert"
    transition="dialog-bottom-transition"
    hide-overlay
    bottom
    style="box-shadow: none"
    content-class="alertDialog"
  >
    <v-alert
      style="margin-top: 70vh"
      :color="type"
      class="mb-0 text-center"
      dark
    >
      {{ testo }}
    </v-alert>

    <v-progress-linear
      :color="type"
      :value="100 - (timeRemaining * 100) / 1400"
    ></v-progress-linear>
  </v-dialog>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      alertIsActive: false,
      testo: "",
      type: "",
      timeRemaining: 0,
    };
  },
  created() {
    this.EventBus.$on("showAlert", ([testo, type]) => {
      this.testo = testo;
      if (!type) type = "success";
      this.type = type;
      this.timeRemaining = 0; //millisecondi
      this.alertIsActive = true;
      this.scaleTime();
    });
  },
  methods: {
    closeAlert() {
      this.alertIsActive = false;
    },
    scaleTime() {
      if (this.timeRemaining < 1400) {
        this.timeRemaining += 50;
        setTimeout(() => {
          this.scaleTime();
        }, 50);
      } else
        setTimeout(() => {
          this.closeAlert();
        }, 100);
    },
  },
};
</script>

<style>
.alertDialog {
  box-shadow: none !important;
}
</style>