<template>
  <v-card>
    <v-card-title
      class="
        mt-5
        pt-0
        px-3
        pb-2
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h3>Checklist</h3>
      <v-spacer></v-spacer>
      <v-tooltip color="#212529" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" small icon @click="resetSetPassi()">
            <v-icon size="15" class="text-typo">fas fa-broom</v-icon>
          </v-btn>
        </template>
        <span>Azzera elenco checklist</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pa-6 pt-0 text-center font-size-root">
      <v-row
        v-for="(passo, index) in this_passi"
        :key="passo['sort']"
        :class="dragging ? 'mt-2 border-bottom' : 'mt-2'"
      >
        <v-col cols="1" class="px-0">
          <div class="mt-3 text-muted text-h5 text-right">
            <b>{{ index + 1 }})</b>
          </div>
        </v-col>
        <v-col cols="7" md="8">
          <v-text-field
            hide-details="auto"
            required
            :readonly="!isEditing"
            :rules="requiredRules"
            v-on:keydown="checkNuovoPasso"
            outlined
            :ref="'passo_' + index"
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Nome"
            autocomplete="off"
            v-model="passo['nome']"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn
            v-if="isEditing"
            small
            icon
            @click="activateImage(index)"
            class="mt-2"
          >
            <v-icon
              size="15"
              :class="passo['requireImage'] ? 'text-green' : 'text-grey'"
              >fas fa-camera</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            v-if="isEditing"
            small
            icon
            @click="rimuoviPasso(index)"
            class="mt-2"
          >
            <v-icon size="15" class="text-typo">fas fa-trash</v-icon>
          </v-btn>
          <v-btn
            v-if="isEditing"
            small
            icon
            class="mt-2 handle"
            style="cursor: move"
          >
            <v-icon size="15" class="text-typo">fas fa-arrows-alt</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pa-6 pt-0 text-center font-size-root">
      <v-btn small icon fab @click="aggiungiPasso()" v-if="isEditing">
        <v-icon size="25" class="text-typo">fas fa-plus</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { nomeRules, requiredRules } from "@/js/validationRules";

export default {
  name: "passi",
  props: {
    passi: Array,
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      this_passi: this.passi || [],
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      dragging: false,
    };
  },
  async created() {
  },
  methods: {
    aggiungiPasso() {
      this.this_passi.push({
        sort: this.this_passi.length,
      });
      let those = this;
      setTimeout(() => {
        if (those.$refs["passo_" + (those.this_passi.length - 1)])
          those.$refs["passo_" + (those.this_passi.length - 1)][0].focus();
      }, 15);
      this.emitChange();
    },
    rimuoviPasso(index) {
      if(confirm("Confermi di voler eliminare questo passo?")) {
        this.this_passi.splice(index, 1);
        this.emitChange();
      }
    },
    resetSetPassi() {
      if (confirm("Sicuro di voler azzerare l'elenco della Checklist")) {
        this.this_passi = [];
      }
    },
    checkNuovoPasso(e) {
      if (e.keyCode === 13 || e.keyCode === 9) {
        e.preventDefault();
        this.aggiungiPasso();
        this.emitChange();
      }
    },
    emitChange() {
      this.$emit("listChanged", this.this_passi);
    },
    activateImage(index) {
      if (this.this_passi[index]["requireImage"] === undefined)
        this.this_passi[index]["requireImage"] = false;
      this.this_passi[index]["requireImage"] =
        !this.this_passi[index]["requireImage"];
      this.$set(this.this_passi, index, this.this_passi[index]);
      this.emitChange();
    },
  },
};
</script>
