import axios from 'axios';

export default {
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stripe/get_all_abbonamenti?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stripe/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async esportaCSV(token) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stripe/esporta_csv?token="+token).then(response => {
            return response.data;
        });
    },
}