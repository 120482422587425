<template>
    <v-container class="listOrdini">
        <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
                <p class="font-weight-600 text-h3 text-typo mb-0">Lista Ordini</p>
            </div>
            <v-card-text class="px-0 py-0">
                <v-data-table no-data-text="Ancora nessun dato"
                              :headers="headers"
                              :loading="loadingData"
                              loading-text="Caricamento Dati.."
                              must-sort
                              :items="ordini"
                              :items-per-page="itemsPerPage"
                              :page="page"
                              :search="search"
                              hide-default-footer
                              class="table thead-light tableListaUtenti"
                              :options="{ sortBy: ['current_period_start'], sortDesc: [true], multiSort: false, mustSort: true }"
                              mobile-breakpoint="0"
                              :item-class="() => { return 'pointer'; }"
                              @click:row="apriOrdine"
                >
                    <template v-slot:item.amount_total="{ item }">
                        <b>{{ italianFormat(item.amount) }}€</b>
                    </template>
                    <template v-slot:item.created="{ item }">
                        {{ formatDate(item.data_creazione) }}
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat height="80">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field hide-details
                                                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                                                  dense
                                                  flat
                                                  filled
                                                  solo
                                                  height="43"
                                                  v-model="search"
                                                  placeholder="Cerca"
                                    >
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :ripple="false"
                                           icon
                                           depressed
                                           @click="reloadLista()"
                                           small
                                           :disabled="loadingData"
                                           v-bind="attrs"
                                           v-on="on"
                                           color="primary">
                                        <v-icon>fas fa-sync</v-icon>
                                    </v-btn>
                                </template>
                                <span>Riaggiorna Lista</span>
                            </v-tooltip>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-card-text>
            <div class="pa-2 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left"
                              next-icon="fa fa-angle-right"
                              class="pagination"
                              color="primary"
                              v-model="page"
                              :length="numberOfPages"
                              circle />
            </div>
        </v-card>
        <v-dialog v-model="dialogOrdine"
                  content-class="position-absolute top-0"
                  :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <SchedaOrdine v-if="dialogOrdine && ordineSelected" :ordineSelected="ordineSelected" @chiudiDialogScheda="chiudiDialogScheda" />
        </v-dialog>
    </v-container>
</template>

<script>
import apiOrdini from "@/js/pages/ordini";
import {momentDate} from "@/js/formatDate";
import SchedaOrdine from "@/components/SchedaOrdine.vue";

export default {
    name: "listOrdini",
    components: {
        SchedaOrdine
    },
    data() {
        return {
            ordini: [],
            token: this.$cookies.get("tokengestionalestorello"),
            search: "",
            page: 1,
            itemsPerPage: 10,
            headers: [
                {
                    text: "Numero",
                    align: "start",
                    sortable: true,
                    value: "numero",
                },
                { text: "Nome", value: "rUtente.nome" },
                { text: "RSA", value: "rRSA.nome" },
                { text: "Totale", value: "amount_total" },
                { text: "Data Creazione", value: "created" },
            ],
            loadingData: false,
            dialogOrdine: false,
            ordineSelected: undefined
        };
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.ordini.length / this.itemsPerPage);
        },
    },
    async created() {
        await this.reloadLista();
    },
    methods: {
        async reloadLista() {
            this.loadingData = true;
            this.ordini = await apiOrdini.getAll(this.token);
            this.loadingData = false;
        },
        apriOrdine(ordine) {
            console.log(ordine)
            this.ordineSelected = ordine;
            this.dialogOrdine = true;
        },
        italianFormat(value = 0) {
            return String(parseFloat(value).toFixed(2)).split(".").join(".");
        },
        formatDate(data) {
            if(data) return momentDate(data);
            else return '';
        },
        chiudiDialogScheda() {
            this.dialogOrdine = false;
        }
    },
};
</script>