<template>
    <v-navigation-drawer width="250"
                         fixed
                         app
                         floating
                         :expand-on-hover="mini"
                         :value="drawer"
    >
        <v-list-item class="pa-0">
            <v-list-item-content class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title class="title d-flex align-center mb-0">
                    <div class="drawer-toggler px-3 cursor-pointer"
                         :class="{ active: togglerActive }"
                         @click="minifyDrawer"
                    >
                        <div class="drawer-toggler-inner">
                            <i class="drawer-toggler-line bg-default"></i>
                            <i class="drawer-toggler-line bg-default"></i>
                            <i class="drawer-toggler-line bg-default"></i>
                        </div>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list nav dense>
            <v-list-item-group>
                <v-list-item v-if="isAdmin"
                             link
                             to="/listUtenti"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-user text-blue</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Utenti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isAdmin"
                             link
                             to="/listRSA"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-home text-green</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>RSA</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isAdmin"
                             link
                             to="/listProdotti"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-clipboard-list text-red</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Prodotti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isAdmin"
                             link
                             to="/listAbbonamenti"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-wallet text-yellow</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Abbonamenti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isAdmin"
                             link
                             to="/listOrdini"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-euro-sign text-green</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ordini</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!isAdmin"
                             link
                             to="/login"
                             class="pb-1 no-default-hover"
                             :ripple="false"
                             active-class="item-active"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-user</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Login</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: "drawer",
    props: {
        drawer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mini: false,
            togglerActive: false,
            selectedItem: undefined,
        };
    },
    computed: {
        isAdmin() {
            return this.$store.state.isAdmin;
        },
    },
    methods: {
        minifyDrawer() {
            this.togglerActive = !this.togglerActive;
            this.mini = !this.mini;
            const body = document.getElementsByTagName("body")[0];

            if (body.classList.contains("drawer-mini"))
                body.classList.remove("drawer-mini");
            else
                body.classList.add("drawer-mini");
        }
    }
};
</script>
