import Vue from 'vue';
import VueRouter from 'vue-router';
import { generalFunction } from '@/js/generalFunction.js';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: {}
    }, {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    }, {
        path: '/listUtenti',
        name: 'listUtenti',
        component: () => import(/* webpackChunkName: "listUtenti" */ '@/views/ListUtenti.vue'),
        meta: {
            need: [],
            adminNeeded: true
        }
    }, {
        path: '/listRSA',
        name: 'listRSA',
        component: () => import(/* webpackChunkName: "listRSA" */ '@/views/ListRSA.vue'),
        meta: {
            need: [],
            adminNeeded: true
        }
    }, {
        path: '/listProdotti',
        name: 'listProdotti',
        component: () => import(/* webpackChunkName: "listProdotti" */ '@/views/ListProdotti.vue'),
        meta: {
            need: [],
            adminNeeded: true
        }
    }, {
        path: '/listAbbonamenti',
        name: 'listAbbonamenti',
        component: () => import(/* webpackChunkName: "listAbbonamenti" */ '@/views/ListAbbonamenti.vue'),
        meta: {
            need: [],
            adminNeeded: true
        }
    }, {
        path: '/listOrdini',
        name: 'listOrdini',
        component: () => import(/* webpackChunkName: "listAbbonamenti" */ '@/views/ListOrdini.vue'),
        meta: {
            need: [],
            adminNeeded: true
        }
    }, {
        path: '/schedaProdotto',
        name: 'schedaProdotto',
        component: () => import(/* webpackChunkName: "schedaProdotto" */ '@/views/SchedaProdotto.vue'),
        meta: {
            need: ['_id'],
            adminNeeded: true
        }
    }
];

const router = new VueRouter({
    history: true,
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

let isAdmin, token;

router.beforeEach( async (to, from, next) => {
    if(from.name === 'Login'){
        token = await generalFunction.getToken();
        isAdmin = await generalFunction.isAdmin(token);
    }
    if(!token) token = await generalFunction.getToken();
    if(!isAdmin) isAdmin = await generalFunction.isAdmin(token);

    let ok = true;
    if(to.meta.need && to.meta.need.length) {
        ok = false;
        for (const paramNeeded of to.meta.need)
            if(to.query && to.query[paramNeeded]) ok = true;
    }
    if(
        to.meta.adminNeeded &&
        !isAdmin
    ) ok = false;

    if(ok) next();
    else if(from.name) next(from.name);
    else next('/');
});

export default router;

