<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Scheda Modello</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="deleteModello()">
        <v-icon size="15" class="text-warning">fas fa-trash</v-icon>
      </v-btn>
      <v-btn small icon @click="$emit('chiudiDialogSchedaModello')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="nomeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="modello['nome']"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <!--label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Decrizione</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Decrizione"
              autocomplete="off"
              v-model="modello['descrizione']"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea-->

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Modifica</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiModelli from "@/js/pages/modelli";
import { nomeRules, requiredRules } from "@/js/validationRules";
import Passi from "@/components/Passi.vue";

export default {
  name: "schedaParticolare",
  props: ["id_prodotto", "modelloProp"],
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      this_ruolo: this.$store.state.ruolo,
      modello: this.modelloProp || {},
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      loading: false,
    };
  },
  components: {
    Passi
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if(!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = { ...this.modello };
          data["id_prodotto"] = this.id_prodotto;
          let newProgetto = await apiModelli.updateOne(this.token, data);
          if (newProgetto) this.$emit("chiudiDialogSchedaModello");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
    async deleteModello() {
      if(!this.loading) {
        if(confirm('Eliminare questo modello?')) {
          this.loading = true;
          await apiModelli.deleteOne(this.token, {
            id_prodotto: this.id_prodotto,
            id_modello: this.modelloProp['_id']
          });
          this.$emit("chiudiDialogSchedaModello");
          this.loading = false;
        }
      }
    }
  },
};
</script>
