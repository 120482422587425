<template>
  <v-card class="card-shadow card-padding">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Modifica Prodotto</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogModificaProdotto')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="nomeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="prodotto['nome']"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Descrizione</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Descrizione"
              autocomplete="off"
              v-model="prodotto['descrizione']"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Categoria</label
            >
            <v-combobox
              v-model="categoria"
              :items="categorie"
              :search-input.sync="searchCategoria"
              @keyup.enter="categoriaSelected"
              hide-selected
              hint="Seleziona o creane una"
              small-chips
              class="pt-0"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Nessun risultato per "<strong>{{
                        searchCategoria
                      }}</strong
                      >". Premi <kbd>invio</kbd> per crearla
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>

            <div class="text-left">
              <input
                ref="fileUpload"
                type="file"
                accept="image/*"
                @change="fileChosen"
              />
              <br>
            </div>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Modifica</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiCategorie from "@/js/pages/categorie";
import apiProdotti from "@/js/pages/prodotti";
import { nomeRules, requiredRules } from "@/js/validationRules";

export default {
  name: "modalModificaProdotto",
  props: {
    prodottoProp: Object,
  },
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      prodotto: this.prodottoProp || {},
      loading: false,
      nome: "",
      descrizione: "",
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      categorie: [],
      categoria: undefined,
      searchCategoria: undefined,
      imageUploaded: undefined,
    };
  },
  async created() {
    this.categorie = await apiCategorie.getAll();
    if (
      this.prodotto["full_categoria"] &&
      this.prodotto["full_categoria"][0] &&
      this.prodotto["full_categoria"][0]["nome"]
    )
      this.categoria = this.prodotto["full_categoria"][0]["nome"];
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if (!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = this.prodotto;
          data["nomeCategoria"] = this.categoria || undefined;
          let prodotto = await apiProdotti.updateOne(this.token, data);
          if (prodotto) this.$emit("chiudiDialogModificaProdotto");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
    async categoriaSelected() {
      if (
        this.categoria &&
        !this.categorie.includes(this.categoria) &&
        !this.loading
      ) {
        this.loading = true;
        let id_categoria = await apiCategorie.insertNew(this.token, {
          nome: this.categoria,
        });
        if (id_categoria && id_categoria !== "categoriaEsistente") {
          this.categorie = await apiCategorie.getAll();
        } else alert("Impossibile creare, nome già esistente");
        this.loading = false;
      }
    },
    async inviaImmagine() {
      if (this.imageUploaded) {
        let formData = new FormData();
        formData.append("immagine", this.imageUploaded);
        await apiProdotti.uploadGuidaAlleTaglie(this.token, formData, this.prodotto['_id']);
      }
    },
    fileChosen(e) {
      const file = e.target.files[0];
      this.imageUploaded = file;
      this.inviaImmagine();
    },
  },
};
</script>
