<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Scheda Utente</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogScheda')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form v-model="formValid" ref="form_data" lazy-validation>
        <v-container>
          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Nome</label
          >
          <v-text-field
            hide-details="auto"
            required
            :rules="nomeRules"
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Nome"
            autocomplete="off"
            v-model="nome"
            class="
              font-size-input
              placeholder-lighter
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Indirizzo</label
          >
          <v-text-field
            hide-details="auto"
            required
            :rules="requiredRules"
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Indirizzo"
            autocomplete="off"
            v-model="indirizzo"
            class="
              font-size-input
              placeholder-lighter
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <v-row>
            <v-col cols="6">
              <label
                class="label-color font-weight-600 d-block text-left text-h5 mt-2"
                >Regione</label
              >
              <v-text-field
                hide-details="auto"
                required
                :rules="requiredRules"
                :readonly="!isEditing"
                outlined
                background-color="rgba(255,255,255,.9)"
                light
                placeholder="Regione"
                autocomplete="off"
                v-model="regione"
                class="
                  font-size-input
                  placeholder-lighter
                  input-alternative input-focused-alternative input-icon
                  mb-4
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label
                class="label-color font-weight-600 d-block text-left text-h5 mt-2"
                >Provincia</label
              >
              <v-text-field
                hide-details="auto"
                required
                :rules="requiredRules"
                :readonly="!isEditing"
                outlined
                background-color="rgba(255,255,255,.9)"
                light
                placeholder="Provincia"
                autocomplete="off"
                v-model="provincia"
                class="
                  font-size-input
                  placeholder-lighter
                  input-alternative input-focused-alternative input-icon
                  mb-4
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-4"
            >Email</label
          >
          <v-text-field
            hide-details="auto"
            :readonly="!isEditing"
            :rules="emailRules"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Email"
            type="email"
            autocomplete="off"
            v-model="email"
            class="
              font-size-input
              placeholder-lighter
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Note</label
          >
          <v-textarea
            hide-details="auto"
            :readonly="!isEditing"
            outlined
            auto-grow
            rows="2"
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Note"
            autocomplete="off"
            v-model="note"
            class="
              font-size-input
              placeholder-lighter
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-textarea>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-col cols="4" class="pt-0">
          <v-btn
            color="primary"
            width="100%"
            @click="submit"
            :disabled="isEditing && !formValid && loading"
            v-text="isEditing ? 'Salva' : 'Indietro'"
          ></v-btn>
        </v-col>
        <v-spacer />
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRSA from "@/js/pages/rsa";
import {
  nomeRules,
  requiredRules,
  emailValidaRules,
} from "@/js/validationRules";

export default {
  name: "schedaRSA",
  props: {
    id: String,
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      email: "",
      nome: "",
      indirizzo: "",
      regione: "",
      provincia: "",
      note: "",
      loading: false,
      formValid: false,
      emailRules: emailValidaRules,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      admin: true,
    };
  },
  async created() {
    let this_id = this.id;
    if (!this_id) this_id = this.$route.query._id;
    let rsa = await apiRSA.getOne(this_id, this.token);
    this.nome = rsa.nome || "";
    this.indirizzo = rsa.indirizzo || "";
    this.regione = rsa.regione || "";
    this.provincia = rsa.provincia || "";
    this.email = rsa.email || "";
    this.note = rsa.note || "";
  },
  methods: {
    async submit() {
      if (this.isEditing) {
        if(!this.loading) {
          this.loading = true;
          if (this.$refs.form_data.validate() && this.formValid) {
            let data = {
              _id: this.id,
              nome: this.nome,
              indirizzo: this.indirizzo,
              regione: this.regione,
              provincia: this.provincia,
              email: this.email,
              note: this.note,
            };
            let update = await apiRSA.updateOne(this.token, data);
            if (update) this.$emit("chiudiDialogScheda", true);
            else alert("Errori durante la creazione, ricontrolla i dati");
          }
          this.loading = false;
        }
      } else this.$emit("chiudiDialogScheda");
    },
  },
};
</script>
