var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"listAbbonamenti"},[_c('v-card',{staticClass:"card-shadow"},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('p',{staticClass:"font-weight-600 text-h3 text-typo mb-0"},[_vm._v("Lista Abbonamenti")])]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table thead-light tableListaUtenti",attrs:{"no-data-text":"Ancora nessun dato","headers":_vm.headers,"loading":_vm.loadingData,"loading-text":"Caricamento Dati..","must-sort":"","items":_vm.abbonamenti,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"hide-default-footer":"","options":{ sortBy: ['current_period_start'], sortDesc: [true], multiSort: false, mustSort: true },"mobile-breakpoint":"0","item-class":function () { return 'pointer'; }},on:{"click:row":_vm.apriAbbonamento},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-check")]),_c('b',{staticClass:"text-success"},[_vm._v(" "+_vm._s(item.status))])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-times")]),_c('b',{staticClass:"text-red"},[_vm._v(" "+_vm._s(item.status))])]]}},{key:"item.amount_total",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.italianFormat(item.amount_total))+"€")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.current_period_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.current_period_start))+" ")]}},{key:"item.cancel_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.cancel_at))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"80"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":"Cerca"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v("fas fa-search")])],1)],2)],1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"icon":"","depressed":"","small":"","disabled":_vm.loadingData,"color":"primary"},on:{"click":function($event){return _vm.reloadLista()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-sync")])],1)]}}])},[_c('span',[_vm._v("Riaggiorna Lista")])]),_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3",attrs:{"ripple":false,"disabled":_vm.loadingData,"color":"primary"},on:{"click":function($event){return _vm.esporta()}}},[_vm._v(" Esporta ")])],1)]},proxy:true}])})],1),_c('div',{staticClass:"pa-2 d-flex justify-end"},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"primary","length":_vm.numberOfPages,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"content-class":"position-absolute top-0","width":_vm.$vuetify.breakpoint.mdAndUp ? '65%' : '90%'},model:{value:(_vm.dialogAbbonamento),callback:function ($$v) {_vm.dialogAbbonamento=$$v},expression:"dialogAbbonamento"}},[(_vm.dialogAbbonamento && _vm.abbonamentoSelected)?_c('SchedaAbbonamento',{attrs:{"abbonamentoSelected":_vm.abbonamentoSelected},on:{"chiudiDialogScheda":_vm.chiudiDialogScheda}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }