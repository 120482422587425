<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card class="card-shadow card-padding">
          <v-card-title
            class="
              pt-0
              px-3
              card-padding
              text-h4 text-typo
              justify-space-between
              border-bottom
            "
          >
            <h2>{{ prodotto["nome"] }}</h2>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialogModificaProdotto = true"
                  elevation="0"
                  :ripple="false"
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="primary"
                >
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica Dati prodotto</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="eliminaProdotto()"
                  elevation="0"
                  :ripple="false"
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="primary"
                >
                  <v-icon small>fas fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Elimina Prodotto</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pb-0 d-flex flex-column px-0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <small>Descrizione</small><br />
                  <h3 style="line-height: 0.5">
                    {{ prodotto["descrizione"] }}
                  </h3>
                </v-col>
                <v-col cols="12">
                  <small>Immagini</small><br />
                  <v-carousel
                    height="200px"
                    hide-delimiter-background
                    v-model="imageProdotto"
                  >
                  <template v-slot:next>
                      <v-icon size="15px" class="pa-1 px-2" @click="nextImage()">fas fa-chevron-right</v-icon>
                  </template>
                  <template v-slot:prev>
                      <v-icon size="15px" class="pa-1 px-2" @click="prevImage()">fas fa-chevron-left</v-icon>
                  </template>
                    <v-carousel-item
                      v-for="(image,i) in prodotto['images']"
                      :key="'image_' + i"
                      :src="endpoint_server + '/' + image"
                      transition="fade-transition"
                    >
                      <div class="float-right pa-1 mt-1 mr-1" style="background-color: white; border-radius: 5px; border: 1px solid grey;">
                        <v-icon size="16px" class="px-1 pr-2" @click="viewImage(image)">fas fa-eye</v-icon>
                        <v-icon size="16px" class="px-1" color="error" @click="deleteImageProdotto(image, i)">fas fa-trash</v-icon>
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
                <v-col cols="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="$refs.fileUpload.click()"
                      elevation="0"
                      :ripple="false"
                      small
                      v-bind="attrs"
                      v-on="on"
                      block
                      class="
                        font-weight-600
                        text-capitalize
                        btn-ls
                        me-2
                        my-2
                        rounded-sm
                      "
                    >
                      <v-icon small>fas fa-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Immagine</span>
                </v-tooltip>
                  <input
                    v-show="false"
                    ref="fileUpload"
                    type="file"
                    accept="image/*"
                    @change="fileChosen"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card
          :class="
            $vuetify.breakpoint.mdAndUp ? 'card-shadow py-1' : 'card-shadow'
          "
        >
          <div class="display-flex">
            <v-tabs
              v-if="!hideTabs"
              v-model="tabParticolare"
              align-with-title
              style="width: 80%"
              :show-arrows="true"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab
                v-for="modello in modelli"
                :key="'tab_' + modello['_id']"
                v-if="!modello['archiviato']"
                >{{ modello["nome"] }}</v-tab
              >
            </v-tabs>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogNewModello = true"
                  style="width: 10%; margin-top: 7px"
                  ><v-icon>fas fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Modello</span>
            </v-tooltip>
          </div>
          <v-tabs-items v-model="tabParticolare" v-if="!hideTabs">
            <v-tab-item
              v-for="(modello, index) in modelli"
              :key="modello['_id']"
              v-if="!modello['archiviato']"
            >
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="9" class="pt-2">
                      <small>Descrizione</small><br />
                      <h3 style="line-height: 0.5">
                        {{ modello["descrizione"] }}
                      </h3>
                    </v-col>
                    <v-col cols="1" class="pt-2 ml-10">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="showModalSchedaParticolare(modello)"
                            elevation="0"
                            :ripple="false"
                            small
                            v-bind="attrs"
                            v-on="on"
                            class="
                              font-weight-600
                              text-capitalize
                              me-2
                              my-2
                              rounded-sm
                            "
                            color="primary"
                          >
                            <v-icon small>fas fa-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Modifica Dati modello</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1" class="pt-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="showModalNewVariazione(modello)"
                            elevation="0"
                            :ripple="false"
                            small
                            v-bind="attrs"
                            v-on="on"
                            class="
                              font-weight-600
                              text-capitalize
                              me-2
                              my-2
                              rounded-sm
                            "
                            color="primary"
                          >
                            <v-icon small>fas fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Aggiungi Varizione</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-0 mt-2">
                      <v-data-table
                        no-data-text="Ancora nessun dato"
                        :headers="headersVariazioni"
                        :items="modello['variazioni']"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                        class="table thead-light"
                        mobile-breakpoint="0"
                        :item-class="
                          () => {
                            return 'pointer';
                          }
                        "
                      >
                        <template v-slot:item.azioni="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="showModalSchedaVariazione(modello, item)"
                                elevation="0"
                                :ripple="false"
                                :disabled="item.archiviato"
                                height="28"
                                min-width="36"
                                width="36"
                                v-bind="attrs"
                                v-on="on"
                                class="
                                  font-weight-600
                                  text-capitalize
                                  btn-ls btn-warning
                                  me-2
                                  my-2
                                  rounded-sm
                                "
                              >
                                <v-icon x-small>fas fa-edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Modifica Variazione</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="removeVariazione(item['_id'])"
                                elevation="0"
                                :ripple="false"
                                height="28"
                                min-width="36"
                                width="36"
                                v-bind="attrs"
                                v-on="on"
                                class="
                                  font-weight-600
                                  text-capitalize
                                  btn-ls btn-danger
                                  me-2
                                  my-2
                                  rounded-sm
                                "
                              >
                                <v-icon x-small>fas fa-trash</v-icon>
                              </v-btn>
                            </template>
                            <span>Elimina Variazione</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogModificaProdotto"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <ModalModificaProdotto
        v-if="dialogModificaProdotto"
        :prodottoProp="prodotto"
        @chiudiDialogModificaProdotto="chiudiDialogModificaProdotto"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogNewModello"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewModello
        v-if="dialogNewModello"
        :prodottoProp="prodotto"
        @chiudiDialogNewModello="chiudiDialogNewModello"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogSchedaModello"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <SchedaModello
        v-if="dialogSchedaModello && prodotto && modelloClicked"
        :id_prodotto="prodotto['_id']"
        :modelloProp="modelloClicked"
        @chiudiDialogSchedaModello="chiudiDialogSchedaModello"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogNewVariazione"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewVariazione
        v-if="dialogNewVariazione"
        :prodottoProp="prodotto"
        :modelloProp="modelloClicked"
        @chiudiDialogNewVariazione="chiudiDialogNewVariazione"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogSchedaVariazione"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <SchedaVariazione
        v-if="dialogSchedaVariazione && prodotto && modelloClicked && variazioneClicked"
        :id_prodotto="prodotto['_id']"
        :id_modello="modelloClicked['_id']"
        :variazioneProp="variazioneClicked"
        @chiudiDialogSchedaVariazione="chiudiDialogSchedaVariazione"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import apiProdotti from "@/js/pages/prodotti";
import apiVariazioni from "@/js/pages/variazioni";
import ModalModificaProdotto from "@/components/ModalModificaProdotto.vue";
import NewModello from "@/components/NewModello.vue";
import SchedaModello from "@/components/SchedaModello.vue";
import NewVariazione from "@/components/NewVariazione.vue";
import SchedaVariazione from "@/components/SchedaVariazione.vue";
import {
  nomeRules,
  requiredRules,
  matricolaRules,
  emailValidaRules,
} from "@/js/validationRules";

export default {
  name: "schedaProgetto",
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      id: this.$route.query._id,
      prodotto: {},
      formValid: false,
      emailRules: emailValidaRules,
      nomeRules: nomeRules,
      matricolaRules: matricolaRules,
      requiredRules: requiredRules,
      isEditing: false,
      tabParticolare: undefined,
      modelli: [],
      dialogModificaProdotto: false,
      dialogNewModello: false,
      dialogSchedaModello: false,
      dialogNewVariazione: false,
      dialogSchedaVariazione: false,
      modelloClicked: undefined,
      variazioneClicked: undefined,
      indexModelloClicked: 0,
      itemsPerPage: 10,
      headersVariazioni: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "45%",
        },
        { 
          text: "Descrizione", 
          value: "descrizione", 
          width: "30%" 
        },
        { 
          text: "Azioni", 
          value: "azioni", 
          width: "15%" 
        },
      ],
      hideTabs: true,
      listaVistePezzi: [],
      imageUploaded: undefined,
      endpoint_server: '',
      imageProdotto: undefined,
    };
  },
  components: {
    ModalModificaProdotto,
    NewModello,
    SchedaModello,
    NewVariazione,
    SchedaVariazione
  },
  async created() {
    this.endpoint_server = process.env.VUE_APP_ENDPOINT_API;
    await this.loadData();
  },
  methods: {
    async chiudiDialogModificaProdotto() {
      await this.loadData();
      this.dialogModificaProdotto = false;
    },
    chiudiDialogNewModello() {
      this.loadData();
      this.dialogNewModello = false;
    },
    async loadData() {
      if(!this.loading) {
        this.loading = true;
        let prodotto = await apiProdotti.getOne(this.id);
        
        this.hideTabs = true;
        this.prodotto = prodotto || {};

        for (let modello of prodotto["modelli"]) {
          let variazioni = [];
          for (let variazione of modello["variazioni"]) {
            if(
              variazione &&
              !variazione['archiviato']
            ) variazioni.push({...variazione});
          } 
          modello["variazioni"] = variazioni;
        }
        this.modelli = prodotto["modelli"] || [];
        setTimeout(() => {
          this.hideTabs = false;
        }, 5);
        this.loading = false;
      }
    },
    showModalSchedaParticolare(modello) {
      this.modelloClicked = modello;
      this.dialogSchedaModello = true;
    },
    async chiudiDialogSchedaModello() {
      await this.loadData();
      this.modelloClicked = undefined;
      this.dialogSchedaModello = false;
    },
    showModalNewVariazione(modello) {
      this.modelloClicked = modello;
      this.dialogNewVariazione = true;
    },
    async chiudiDialogNewVariazione() {
      await this.loadData();
      this.modelloClicked = undefined;
      this.dialogNewVariazione = false;
    },
    showModalSchedaVariazione(modello, variazione) {
      this.modelloClicked = modello;
      this.variazioneClicked = variazione;
      this.dialogSchedaVariazione = true;
    },
    async chiudiDialogSchedaVariazione() {
      await this.loadData();
      this.modelloClicked = undefined;
      this.variazioneClicked = undefined;
      this.dialogSchedaVariazione = false;
    },
    async removeVariazione(id_variazione) {
      if(!this.loading) {
        if(
          id_variazione &&
          confirm('Eliminare questa variazione?')
        ) {
          await apiVariazioni.deleteOne(this.token, { id_variazione: id_variazione });
          await this.loadData();
        }
      }
    },
    async inviaImmagine() {
      if (this.imageUploaded) {
        let formData = new FormData();
        formData.append("immagine", this.imageUploaded);
        await apiProdotti.uploadImage(this.token, formData, this.id);
        await this.loadData();
        this.imageUploaded = undefined;
      }
    },
    fileChosen(e) {
      const file = e.target.files[0];
      this.imageUploaded = file;
      this.inviaImmagine();
    },
    nextImage() {
      if(this.prodotto['images'].length > this.imageProdotto)
        this.imageProdotto++;
    },
    prevImage() {
      if(this.imageProdotto > 0)
        this.imageProdotto--;
    },
    async deleteImageProdotto(image, index) {
      if(
        !this.loading &&
        image &&
        confirm('Sicuro di voler eliminare questa immagine?')
      ) {
        await apiProdotti.deleteImage(this.token, {
          image_name: image,
          id_prodotto: this.id
        });
        await this.loadData();
      }
    },
    async eliminaProdotto() {
      if(confirm('Sicuro di voler eliminare il prodotto selezionato?')) {
        await apiProdotti.deleteOne(this.token, { _id: this.id });
        this.$router.push('/listProdotti');
      }
    },
    async viewImage(image) {
      if(image)
        window.open(this.endpoint_server + '/' + image);
    }
  },
};
</script>