<template>
  <v-row class="d-flex justify-center mt-16">
    <v-col cols="12" md="5">
      <v-form @submit="submit">
        <v-card class="card-shadow">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Accedi usando la tua email</small>
            <v-text-field
              hide-details
              outlined
              placeholder="Email"
              autocomplete="email"
              type="email"
              v-model="email"
              class="
                font-size-input
                text-color-light
                input-alternative input-focused-alternative input-icon
                my-5
              "
            >
              <template slot="prepend-inner">
                <v-icon color="color-light" x-small>far fa-id-card</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              hide-details
              outlined
              placeholder="Password"
              autocomplete="password"
              type="password"
              v-model="pass"
              class="
                font-size-input
                text-color-light
                input-alternative input-focused-alternative input-icon
                my-5
              "
            >
              <template slot="prepend-inner">
                <v-icon class="color-light" x-small>fas fa-lock</v-icon>
              </template>
            </v-text-field>

            <v-checkbox
              v-model="checkbox"
              :ripple="false"
              class="checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-body-2">Resta connesso</span>
              </template>
            </v-checkbox>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-6
              "
              color="primary"
              type="submit"
              >Entra</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import apiLogin from "@/js/pages/login";
import apiUtente from "@/js/pages/utenti";

export default {
  name: "login",
  data() {
    return {
      checkbox: false,
      loading: false,
      email: "",
      pass: "",
    };
  },
  methods: {
    async submit(e) {
      if (!this.loading) {
        e.preventDefault();
        this.loading = true;
        let data = {
          email: this.email,
          pass: this.pass,
        };
        let token = await apiLogin.login(data);
        if (token) {
          let expires = 30;
          if (this.rememberMe) expires = 365;
          this.$cookies.set('tokengestionalestorello', token, '365d');
          this.$store.state.token = token;
          this.EventBus.$emit("login");
          let admin = await apiUtente.getMyAdmin(token);
          if (admin) this.$router.push("/").catch((e) => {});
          else this.EventBus.$emit("showAlert", ["Errore Utente", "error"]);
        } else
          this.EventBus.$emit("showAlert", ["Credenziali Errate", "error"]);

        this.loading = false;
      }
    },
  },
};
</script>
