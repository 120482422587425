import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllValid(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/get_all_valid?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async uploadImage(token, formData, id_prodotto) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/upload_image?token="+token+'&id_prodotto='+id_prodotto, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    },
    async uploadGuidaAlleTaglie(token, formData, id_prodotto) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/upload_guida_alle_taglie?token="+token+'&id_prodotto='+id_prodotto, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    },
    async deleteImage(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/prodotti/delete_image?token="+token, params).then(response => {
            return response.data;
        });
    },
}