export const numberRequiredRules = [
    v => v.length !== 0 || 'Campo Richiesto',
    v => !v || /^\d+$/.test(v) || 'Numero non valido',
];
export const requiredRules = [
    v => !!v || 'Campo Richiesto',
];
export const testoRules = [
    v => !!v || 'Richiesto',
    v => (v && v.length <= 40) || 'Il campo non deve superare i 40 caratteri',
];
export const nameRules = [
    v => !!v || 'Nome Richiesto',
    v => (v && v.length <= 15) || 'Il nome non deve superare i 15 caratteri',
];
export const annoRules = [
    v => !!v || 'Anno Richiesto',
    v => !isNaN(v) && parseInt(v) > 1700 && parseInt(v) < 3000 || 'Anno non valido',
];
export const nomeRules = [
    v => !!v || 'Nome Richiesto',
    v => (v && v.length <= 40) || 'Il nominativo non deve superare i 40 caratteri',
];
export const loginPasswordRules = [
    v => !!v || 'Password Richiesta',
    v => (v && v.length >= 8) || 'La Password deve almeno contenere 8 caratteri',
];
export const signupPasswordRules = [
    v => !!v || 'Password Richiesta',
    v => /[a-z]/.test(v) || 'La Password deve contenere almeno 1 carattere minuscolo',
    v => /[A-Z]/.test(v) || 'La Password deve contenere almeno 1 carattere maiuscolo',
    v => /\d/.test(v) || 'La Password deve contenere almeno 1 numero',
    v => (v && v.length >= 8) || 'La Password deve almeno contenere 8 caratteri',
];
export const emailRules = [
    v => !!v || 'Email richiesta',
    v => (!v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase())) || 'Indirizzo Email invalido'
];
export const emailValidaRules = [
    v => (!v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase())) || 'Indirizzo Email invalido'
];
export const matricolaRules = [
    v => !!v || 'Matricola richiesta',
    v => /^[0-9]{3,6}$/.test(String(v).toLowerCase()) || 'Matricola non valida'
];
export const serialeRules = [
    v => !!v || 'Seriale richiesto',
];
export const telefonoRules = [
    v => !v || /^[\+]?[(]?[0-9]{2,3}?[)]?[-\s\.]?[0-9]{7,10}$/.test(v) || 'Il numero non è corretto',
];
export const documentoRules = [
    v => !!v || 'Campo richiesto',
    v => (v && v.size < 104857600) || 'File troppo grande (max 100MB)',
];
export const indirizzoRules = [
    v => !!v || 'Indirizzo Richiesto',
];
export const codiceFiscaleRules = [
    v => !v || /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i.test(v) || 'CF non valido',
];
export const partitaIvaRules = [
    v => !v || /^[0-9]{11}$/i.test(v) || 'P.Iva non valida',
];