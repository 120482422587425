<template>
  <v-container class="listProdotti">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Prodotti</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="prodotti"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          class="table thead-light tableListaUtenti"
          mobile-breakpoint="0"
          :item-class="() => {return 'pointer';}"
          @click:row="apriProdotto"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                @click="dialogNew = true"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  ms-3
                "
                color="primary"
                >Aggiungi</v-btn
              >
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="dialogNew"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewProdotto v-if="dialogNew" @chiudiDialogNew="chiudiDialogNew" />
    </v-dialog>
  </v-container>
</template>

<script>
import apiProdotti from "@/js/pages/prodotti";
import NewProdotto from "@/components/NewProdotto.vue";

export default {
  name: "listProdotti",
  components: {
    NewProdotto,
  },
  data() {
    return {
      prodotti: [],
      token: this.$cookies.get("tokengestionalestorello"),
      ruolo: this.$store.state.ruolo,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "50%",
        },
        { text: "Descrizione", value: "descrizione", width: "35%" },
      ],
      dialogNew: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.prodotti.length / this.itemsPerPage);
    }
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async reloadLista() {
      let prodotti = await apiProdotti.getAll(this.token);
      this.prodotti = prodotti;
    },
    apriProdotto(prodotto) {
      if(prodotto)
        this.$router.push('/schedaProdotto?_id=' + prodotto['_id']);
    },
    async chiudiDialogNew() {
      await this.reloadLista();
      this.dialogNew = false;
    },
  },
};
</script>