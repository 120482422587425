<template>
    <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
        <v-card-title class="pt-0 px-3 card-padding text-h4 text-typov justify-space-between border-bottom">
            <h2>Scheda Utente</h2>
            <v-spacer></v-spacer>
            <v-btn small icon @click="$emit('chiudiDialogScheda')">
                <v-icon size="20" class="text-typo">fas fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 d-flex flex-column px-0">
            <v-form v-model="formValid" ref="form_data" lazy-validation>
                <v-container>
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-2">Nome</label>
                    <v-text-field hide-details="auto"
                                  required
                                  :rules="nomeRules"
                                  :readonly="!isEditing"
                                  outlined
                                  background-color="rgba(255,255,255,.9)"
                                  light
                                  placeholder="Nome"
                                  autocomplete="off"
                                  v-model="nome"
                                  class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-4" />
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-2">Password</label>
                    <v-text-field
                        hide-details="auto"
                        required
                        :readonly="!isEditing"
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        light
                        placeholder="Password"
                        autocomplete="off"
                        type="password"
                        v-model="password"
                        class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-6" />
                    <v-combobox v-model="rsa_utente"
                                :items="elenco_rsa"
                                label="RSA"
                                outlined
                                class="font-size-input input-alternative input-focused-alternative mb-3"
                                :item-text="returnItem"
                                item-value="_id"
                                hide-details="auto"
                                dense/>
                    <v-checkbox v-model="admin"
                                color="primary"
                                :ripple="false"
                                class="ma-0 checkbox-custom checkbox-thinner"
                                hide-details>
                        <template v-slot:label>
                            <span class="text-muted text-body-2 ls-0">Admin</span>
                        </template>
                    </v-checkbox>
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-4">Email</label>
                    <v-text-field hide-details="auto"
                                  :readonly="!isEditing"
                                  :rules="emailRules"
                                  outlined
                                  background-color="rgba(255,255,255,.9)"
                                  light
                                  placeholder="Email"
                                  type="email"
                                  autocomplete="off"
                                  v-model="email"
                                  class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-4" />
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-4">Codice Fiscale</label>
                    <v-text-field hide-details="auto"
                                  :readonly="!isEditing"
                                  :rules="requiredRules"
                                  outlined
                                  background-color="rgba(255,255,255,.9)"
                                  light
                                  placeholder="Codice Fiscale"
                                  type="email"
                                  autocomplete="off"
                                  v-model="codice_fiscale"
                                  class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-4"/>
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-2">Indirizzo Fatturazione</label>
                    <v-textarea hide-details="auto"
                                :readonly="!isEditing"
                                outlined
                                auto-grow
                                rows="2"
                                background-color="rgba(255,255,255,.9)"
                                light
                                placeholder="Indirizzo Fatturazione"
                                autocomplete="off"
                                v-model="indirizzo_fatturazione"
                                class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-4"/>
                    <label class="label-color font-weight-600 d-block text-left text-h5 mt-2">Note</label>
                    <v-textarea hide-details="auto"
                                :readonly="!isEditing"
                                outlined
                                auto-grow
                                rows="2"
                                background-color="rgba(255,255,255,.9)"
                                light
                                placeholder="Note"
                                autocomplete="off"
                                v-model="note"
                                class="font-size-input placeholder-lighter input-alternative input-focused-alternative input-icon mb-4"/>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-spacer />
                <v-col cols="4" class="pt-0">
                    <v-btn color="primary"
                           width="100%"
                           @click="submit"
                           :disabled="isEditing && !formValid && loading"
                           v-text="isEditing ? 'Salva' : 'Indietro'"/>
                </v-col>
                <v-spacer />
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
import apiUtenti from "@/js/pages/utenti";
import apiRSA from "@/js/pages/rsa";
import {emailRules, nomeRules, requiredRules,} from "@/js/validationRules";

export default {
    name: "schedaUtente",
    props: {
        id: String,
        isEditing: { type: Boolean, default: false },
    },
    data() {
        return {
            token: this.$cookies.get("tokengestionalestorello"),
            email: "",
            nome: "",
            password: "",
            ruolo: "",
            codice_fiscale: "",
            indirizzo_fatturazione: "",
            showPwd: false,
            note: "",
            loading: false,
            utente: {},
            formValid: false,
            emailRules: emailRules,
            nomeRules: nomeRules,
            requiredRules: requiredRules,
            admin: true,
            rsa_utente: undefined,
            elenco_rsa: [],
        };
    },
    async created() {
        let this_id = this.id;
        if (!this_id) this_id = this.$route.query._id;
        let utente = await apiUtenti.getOneFromId(this_id, this.token);
        this.nome = utente.nome || "";
        this.email = utente.email || "";
        this.note = utente.note || "";
        this.codice_fiscale = utente.codice_fiscale || "";
        this.indirizzo_fatturazione = utente.indirizzo_fatturazione || "";
        this.admin = utente.admin || false;
        let rsa_utente = utente.id_rsa;
        this.elenco_rsa = await apiRSA.getAllValid();
        if(rsa_utente)
            this.rsa_utente = this.elenco_rsa.find( x => x['_id'] === rsa_utente) || undefined;
    },
    methods: {
        async submit() {
            if (this.isEditing) {
                if(!this.loading) {
                    this.loading = true;
                    if (this.$refs.form_data.validate() && this.formValid) {
                        let data = {
                            _id: this.id,
                            nome: this.nome,
                            email: this.email,
                            password: this.password,
                            note: this.note,
                            codice_fiscale: this.codice_fiscale,
                            indirizzo_fatturazione: this.indirizzo_fatturazione,
                            admin: this.admin,
                            id_rsa: this.rsa_utente && this.rsa_utente['_id'] ? this.rsa_utente['_id'] : '',
                        };
                        let update = await apiUtenti.updateOne(this.token, data);
                        if (update === "emailEsistente") alert("Email esistente!");
                        else if (update) this.$emit("chiudiDialogScheda", true);
                        else alert("Errori durante la creazione, ricontrolla i dati");
                    }
                    this.loading = false;
                }
            } else this.$emit("chiudiDialogScheda");
        },
        returnItem(item) {
            return item['nome'] + (item['indirizzo'] ? (' - ' + item['indirizzo']) : '');
        }
    },
};
</script>
