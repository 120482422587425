<template>
    <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
        <v-card-title class="pt-0 px-3 card-padding text-h4 text-typo justify-space-between border-bottom">
            <h2>Scheda Ordine</h2>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :ripple="false"
                           icon
                           v-bind="attrs"
                           v-on="on"
                           :href="'https://dashboard.stripe.com/test/payments/' +ordineSelected['payment_intent']"
                           target="_BLANK"
                    >
                        <v-icon class="ml-3" color="primary" size="25">fas fa-eye</v-icon>
                    </v-btn>
                </template>
                <span>Vedi su stripe</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn small icon @click="$emit('chiudiDialogScheda')">
                <v-icon size="20" class="text-typo">fas fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 d-flex flex-column px-0">
            <v-form v-model="formValid" ref="form_data" lazy-validation>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3>
                                Dettagli Utente
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :ripple="false"
                                               icon
                                               v-bind="attrs"
                                               v-on="on"
                                               :href="'https://dashboard.stripe.com/test/customers/' +ordineSelected['rUtente']['_id']"
                                               class="mt-n2"
                                               target="_BLANK"
                                        >
                                            <v-icon size="15" color="primary">fas fa-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Vedi su stripe</span>
                                </v-tooltip>
                            </h3>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Email</label>
                            <v-text-field hide-details="auto"
                                          ref="emailUtente"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Email"
                                          autocomplete="off"
                                          v-model="ordineSelected['rUtente']['email']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon">
                                <template v-slot:append>
                                    <v-icon @click="copyText($event, ordineSelected['rUtente']['email'],'emailUtente')">fas fa-copy</v-icon>
                                    <v-icon @click="openLink('mailto:' + ordineSelected['rUtente']['email']) " class="ml-2">fas fa-reply</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Nome</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Nome"
                                          ref="nomeUtente"
                                          autocomplete="off"
                                          v-model="ordineSelected['rUtente']['nome']"
                                          class=" font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rUtente']['nome'],'nomeUtente')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Codice Fiscale</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Codice Fiscale"
                                          ref="codiceFiscale"
                                          autocomplete="off"
                                          v-model="ordineSelected['rUtente']['codice_fiscale']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rUtente']['codice_fiscale'],'codiceFiscale') ">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Telefono</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Telefono"
                                          autocomplete="off"
                                          ref="telefono"
                                          v-model="ordineSelected['rUtente']['telefono']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon">
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rUtente']['telefono'],'telefono')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Indirizzo Fatturazione</label>
                            <v-textarea hide-details="auto"
                                        auto-grow
                                        rows="2"
                                        required
                                        placeholder="Indirizzo Fatturazione"
                                        autocomplete="off"
                                        ref="indirizzo_fatturazione"
                                        readonly
                                        v-model="ordineSelected['rUtente']['indirizzo_fatturazione']"
                                        class="font-size-input placeholder-lighter text-color-light input-focused-alternative input-icon customAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rUtente']['indirizzo_fatturazione'],'indirizzo_fatturazione')">fas fa-copy</v-icon>
                                </template>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <h3>Dettagli RSA</h3>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Email</label>
                            <v-text-field hide-details="auto"
                                          ref="emailRSA"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Email"
                                          autocomplete="off"
                                          v-model="ordineSelected['rRSA']['email']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rRSA']['email'],'emailRSA')">fas fa-copy</v-icon>
                                    <v-icon @click="openLink('mailto:' +ordineSelected['rRSA']['email'])" class="ml-2">fas fa-reply</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Nome</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Nome"
                                          ref="nomeRSA"
                                          autocomplete="off"
                                          v-model="ordineSelected['rRSA']['nome']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-iconcustomAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rRSA']['nome'],'nomeRSA')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Indirizzo</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Indirizzo"
                                          ref="indirizzoRSA"
                                          autocomplete="off"
                                          v-model="ordineSelected['rRSA']['indirizzo']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon"
                            >
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rRSA']['indirizzo'],'indirizzoRSA')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="7" md="8">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Regione</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Regione"
                                          ref="regioneRSA"
                                          autocomplete="off"
                                          v-model="ordineSelected['rRSA']['regione']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon">
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rRSA']['regione'],'regioneRSA')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="5" md="4">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Provincia</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Provincia"
                                          ref="provinciaRSA"
                                          autocomplete="off"
                                          v-model="ordineSelected['rRSA']['provincia']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon">
                                <template v-slot:append>
                                    <v-icon @click="copyText($event,ordineSelected['rRSA']['provincia'],'provinciaRSA')">fas fa-copy</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <h3>Dettagli ordine</h3>
                        </v-col>
                        <v-col cols="7" md="2">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Totale</label>
                            <v-text-field hide-details="auto"
                                          readonly
                                          background-color="rgba(255,255,255,.9)"
                                          light
                                          placeholder="Provincia"
                                          ref="provinciaRSA"
                                          autocomplete="off"
                                          type="number"
                                          v-model="ordineSelected['amount']"
                                          class="font-size-input placeholder-lighter input-focused-alternative input-icon customAppendIcon" />
                        </v-col>
                        <v-col cols="6" md="2">
                            <label class="label-color font-weight-600 d-block text-left text-h5">Data Creazione</label>
                            <h4>{{ formatDate(ordineSelected['data_creazione']) }}</h4>
                        </v-col>
                        <v-col cols="12">
                            <label class="label-color font-weight-600 d-block text-left text-h5 mb-2">Prodotti</label>
                            <ul>
                                <li class="text-h4 mb-1"
                                    v-for="(prodotto, index) of ordineSelected['products']"
                                    :key="index + prodotto"
                                >
                                    <b>{{ prodotto.name }} - {{ priceFormat(prodotto.price) }} €</b>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-spacer />
                <v-col cols="12" md="4" class="pt-0">
                    <v-btn color="primary"
                           width="100%"
                           @click="$emit('chiudiDialogScheda')">
                        Indietro
                    </v-btn>
                </v-col>
                <v-spacer />
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
import {momentDate, unixToItalian} from "@/js/formatDate";

export default {
    name: "schedaOrdini",
    props: {
        ordineSelected: Object,
    },
    data() {
        return {
            token: this.$cookies.get("tokengestionalestorello"),
            loading: false,
            formValid: false,
            admin: true,
        };
    },
    async created() {},
    methods: {
        async copyText(div, value, refValue) {
            div.target.classList.remove("fa-copy");
            div.target.classList.add("fa-check-circle");
            div.target.classList.add("text-green");
            setTimeout(() => {
                div.target.classList.add("fa-copy");
                div.target.classList.remove("fa-check-circle");
                div.target.classList.remove("text-green");
            }, 2000);

            if (
                refValue &&
                this.$refs[refValue] &&
                this.$refs[refValue]["$el"] &&
                this.$refs[refValue]["$el"].querySelector("input")
            ) {
                this.$refs[refValue]["$el"].querySelector("input").focus();
                this.$refs[refValue]["$el"].querySelector("input").select();
            }
            try {
                await navigator.clipboard.writeText(value);
            } catch (e) {
                console.log(e);
                alert("Impossibile copiare nel blocco note");
            }
        },
        openLink(link) {
            let a = document.createElement("a");
            let linkText = document.createTextNode("");
            a.appendChild(linkText);
            a.href = link;
            document.body.appendChild(a);
            a.click();
        },
        formatDate(data) {
            if(data) return momentDate(data);
            else return '';
        },
        priceFormat(price) {
            let priceFormatted = (parseInt(price) / 100).toFixed(2);
            return String(priceFormatted).replace(".", ",");
        },
    },
};
</script>
