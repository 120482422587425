var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"listUtenti"},[_c('v-card',{staticClass:"card-shadow"},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('p',{staticClass:"font-weight-600 text-h3 text-typo mb-0"},[_vm._v("Lista Utenti")])]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table thead-light tableListaUtenti",attrs:{"no-data-text":"Ancora nessun dato","headers":_vm.headers,"items":_vm.utenti,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"hide-default-footer":"","item-class":_vm.rowBackground,"options":{ sortBy: ['data_creazione'], sortDesc: [true], multiSort: false, mustSort: true },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"80"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":"Cerca"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v("fas fa-search")])],1)],2)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3",attrs:{"elevation":"0","ripple":false,"height":"43","color":"primary"},on:{"click":function($event){_vm.dialogNew = true}}},[_vm._v(" Aggiungi ")])],1)]},proxy:true},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.admin && !item.archiviato)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-check")]),_vm._v(" Si ")]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-times")]),_vm._v(" No ")]]}},{key:"item.azioni",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm",attrs:{"elevation":"0","ripple":false,"disabled":item.archiviato,"height":"28","min-width":"36","width":"36","color":"#fb6340"},on:{"click":function($event){return _vm.editUtente(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm",attrs:{"elevation":"0","ripple":false,"disabled":item.archiviato,"height":"28","min-width":"36","width":"36","color":"#f5365c"},on:{"click":function($event){return _vm.removeUtente(item['_id'])}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}])})],1),_c('div',{staticClass:"pa-2 d-flex justify-end"},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"primary","length":_vm.numberOfPages,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"content-class":"position-absolute top-0","width":_vm.$vuetify.breakpoint.mdAndUp ? '65%' : '90%'},model:{value:(_vm.dialogScheda),callback:function ($$v) {_vm.dialogScheda=$$v},expression:"dialogScheda"}},[(_vm.dialogScheda)?_c('SchedaUtente',{attrs:{"isEditing":_vm.isEditingUtente,"id":_vm.idUtenteClicked},on:{"chiudiDialogScheda":_vm.chiudiDialogScheda}}):_vm._e()],1),_c('v-dialog',{attrs:{"content-class":"position-absolute top-0","width":_vm.$vuetify.breakpoint.mdAndUp ? '65%' : '90%'},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[(_vm.dialogNew)?_c('NewUtente',{on:{"chiudiDialogNew":_vm.chiudiDialogNew}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }