import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllValid(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/get_all_valid?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/modelli/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}