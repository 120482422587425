<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1">
        <h3>Home</h3>
        <center v-if="!token">
          <router-link to="/login">Effettua il login per accedere</router-link>
        </center>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Home',
    data() {
      return {
        token: this.$cookies.get('tokengestionalestorello')
      }
    },
  }
</script>
