import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllValid() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/get_all_valid").then(response => {
            return response.data;
        });
    },
    async getOne(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rsa/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}