<template>
  <v-container class="listRSA">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista RSA</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="rsa"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          :item-class="rowBackground"
          class="table thead-light tableListaUtenti"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                @click="dialogNew = true"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  ms-3
                "
                color="primary"
                >Aggiungi</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.admin="{ item }">
            <template v-if="item.admin && !item.archiviato">
              <v-icon color="success">fas fa-check</v-icon> Si
            </template>
            <template v-else>
              <v-icon color="error">fas fa-times</v-icon> No
            </template>
          </template>
          <template v-slot:item.azioni="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editRSA(item)"
                  elevation="0"
                  :ripple="false"
                  :disabled="item.archiviato"
                  height="28"
                  min-width="36"
                  width="36"
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-warning
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#fb6340"
                >
                  <v-icon x-small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="removeRSA(item['_id'])"
                  elevation="0"
                  :ripple="false"
                  :disabled="item.archiviato"
                  height="28"
                  min-width="36"
                  width="36"
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-danger
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#f5365c"
                >
                  <v-icon x-small>fas fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Elimina</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog
      v-model="dialogScheda"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <SchedaRSA
        v-if="dialogScheda"
        :isEditing="isEditingRSA"
        :id="idRSAClicked"
        @chiudiDialogScheda="chiudiDialogScheda"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogNew"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewRSA v-if="dialogNew" @chiudiDialogNew="chiudiDialogNew" />
    </v-dialog>
  </v-container>
</template>

<script>
import apiRSA from "@/js/pages/rsa";
import SchedaRSA from "@/components/SchedaRSA.vue";
import NewRSA from "@/components/NewRSA.vue";

export default {
  name: "listRSA",
  components: {
    SchedaRSA,
    NewRSA,
  },
  data() {
    return {
      rsa: [],
      token: this.$cookies.get("tokengestionalestorello"),
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "15%",
        },
        { text: "Email", value: "email", width: "20%" },
        { text: "Indirizzo", value: "indirizzo", width: "25%" },
        { text: "Note", value: "note", width: "20%" },
        { text: "Azioni", value: "azioni", width: "15%" },
      ],
      isEditingRSA: false,
      dialogScheda: false,
      idRSAClicked: undefined,
      dialogNew: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.rsa.length / this.itemsPerPage);
    }
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async chiudiDialogScheda(refresh = false) {
      if (refresh) await this.reloadLista();
      this.dialogScheda = false;
    },
    async chiudiDialogNew() {
      await this.reloadLista();
      this.dialogNew = false;
    },
    async reloadLista() {
      let rsa = await apiRSA.getAll(this.token);
      this.rsa = rsa;
    },
    async editRSA(item) {
      this.idRSAClicked = item["_id"];
      this.isEditingRSA = true;
      this.dialogScheda = true;
    },
    async removeRSA(id_rsa) {
      if (confirm("Eliminare la RSA?")) {
        let data = { _id: id_rsa };
        await apiRSA.deleteOne(this.token, data);
        this.reloadLista();
      }
    },
    rowBackground(item) {
      return item.archiviato ? 'rowArchiviata' : '';
    }
  },
};
</script>