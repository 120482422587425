<template>
  <v-card
    :class="
      $vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'
    "
  >
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Nuovo Utente</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogNew')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="nomeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="nome"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Password</label
            >
            <v-text-field
              hide-details="auto"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Password"
              autocomplete="off"
              type="password"
              v-model="password"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-6
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Codice Fiscale</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="requiredRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Codice Fiscale"
              autocomplete="off"
              v-model="codice_fiscale"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-6
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Indirizzo Fatturazione</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              required
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Indirizzo Fatturazione"
              autocomplete="off"
              :rules="requiredRules"
              v-model="indirizzo_fatturazione"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-6
              "
            ></v-textarea>

            <v-combobox
              v-model="rsa_utente"
              :items="elenco_rsa"
              label="RSA"
              outlined
              class="
                font-size-input
                input-alternative input-focused-alternative
                mb-3
              "
              :item-text="returnItem"
              item-value="_id"
              hide-details="auto"
              dense
            ></v-combobox>

            <v-checkbox
              v-model="admin"
              color="primary"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0">Admin</span>
              </template>
            </v-checkbox>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-4"
              >Email</label
            >
            <v-text-field
              hide-details="auto"
              outlined
              :rules="emailRules"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Email"
              type="email"
              autocomplete="off"
              v-model="email"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Note</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Note"
              autocomplete="off"
              v-model="note"
              class="
                font-size-input
                placeholder-lighter
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Crea</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRSA from "@/js/pages/rsa";
import apiUtenti from "@/js/pages/utenti";
import { emailRules, nomeRules, requiredRules } from "@/js/validationRules";

export default {
  name: "newUtente",
  data() {
    return {
      token: this.$cookies.get("tokengestionalestorello"),
      this_ruolo: this.$store.state.ruolo,
      email: "",
      nome: "",
      password: "",
      admin: "",
      codice_fiscale: "",
      indirizzo_fatturazione: "",
      note: "",
      ruolo: "",
      formValid: false,
      emailRules: emailRules,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      listMatricole: [],
      loading: false,
      admin: false,
      rsa_utente: undefined,
      elenco_rsa: [],
    };
  },
  async created() {
    let list_rsa = await apiRSA.getAllValid();
    this.elenco_rsa = list_rsa;
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if (!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = {
            nome: this.nome,
            email: this.email,
            note: this.note,
            codice_fiscale: this.codice_fiscale,
            indirizzo_fatturazione: this.indirizzo_fatturazione,
            password: this.password,
            admin: this.admin,
            id_rsa:
              this.rsa_utente && this.rsa_utente["_id"]
                ? this.rsa_utente["_id"]
                : "",
          };
          let newUtente = await apiUtenti.insertNew(this.token, data);
          if (newUtente === "emailEsistente") alert("Email già inserita!");
          else if (newUtente) this.$emit("chiudiDialogNew");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
    returnItem(item) {
      return (
        item["nome"] + (item["indirizzo"] ? " - " + item["indirizzo"] : "")
      );
    },
  },
};
</script>
