import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/categorie/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/categorie/get_all").then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/categorie/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/categorie/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}