import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getMyAdmin(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_one?token="+token).then(response => {
            return response.data;
        });
    },
    async getOneFromId(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async getOneFromToken(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_one?token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAllMatricole(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_all_matricole?token="+token).then(response => {
            return response.data;
        });
    },
}