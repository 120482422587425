import axios from 'axios';

export default {
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stripe/get_all_ordini?token="+token).then(response => {
            return response.data;
        });
    },
    /*async getOne(_id, token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stripe/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },*/
}