<template>
    <v-container class="listAbbonamenti">
        <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
                <p class="font-weight-600 text-h3 text-typo mb-0">Lista Abbonamenti</p>
            </div>
            <v-card-text class="px-0 py-0">
                <v-data-table no-data-text="Ancora nessun dato"
                              :headers="headers"
                              :loading="loadingData"
                              loading-text="Caricamento Dati.."
                              must-sort
                              :items="abbonamenti"
                              :items-per-page="itemsPerPage"
                              :page="page"
                              :search="search"
                              hide-default-footer
                              class="table thead-light tableListaUtenti"
                              :options="{ sortBy: ['current_period_start'], sortDesc: [true], multiSort: false, mustSort: true }"
                              mobile-breakpoint="0"
                              :item-class="() => { return 'pointer'; }"
                              @click:row="apriAbbonamento"
                >
                    <template v-slot:item.status="{ item }">
                        <template v-if="item.status === 'active'">
                            <v-icon color="success">fas fa-check</v-icon>
                            <b class="text-success"> {{ item.status }}</b>
                        </template>
                        <template v-else>
                            <v-icon color="error">fas fa-times</v-icon>
                            <b class="text-red"> {{ item.status }}</b>
                        </template>
                    </template>
                    <template v-slot:item.amount_total="{ item }">
                        <b>{{ italianFormat(item.amount_total) }}€</b>
                    </template>
                    <template v-slot:item.created="{ item }">
                        {{ formatDate(item.created) }}
                    </template>
                    <template v-slot:item.current_period_start="{ item }">
                        {{ formatDate(item.current_period_start) }}
                    </template>
                    <template v-slot:item.cancel_at="{ item }">
                        {{ formatDate(item.cancel_at) }}
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat height="80">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field hide-details
                                                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                                                  dense
                                                  flat
                                                  filled
                                                  solo
                                                  height="43"
                                                  v-model="search"
                                                  placeholder="Cerca"
                                    >
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :ripple="false"
                                           icon
                                           depressed
                                           @click="reloadLista()"
                                           small
                                           :disabled="loadingData"
                                           v-bind="attrs"
                                           v-on="on"
                                           color="primary">
                                        <v-icon>fas fa-sync</v-icon>
                                    </v-btn>
                                </template>
                                <span>Riaggiorna Lista</span>
                            </v-tooltip>
                            <v-btn :ripple="false"
                                   @click="esporta()"
                                   class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                                   :disabled="loadingData"
                                   color="primary">
                                Esporta
                            </v-btn>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-card-text>
            <div class="pa-2 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left"
                              next-icon="fa fa-angle-right"
                              class="pagination"
                              color="primary"
                              v-model="page"
                              :length="numberOfPages"
                              circle />
            </div>
        </v-card>
        <v-dialog v-model="dialogAbbonamento"
                  content-class="position-absolute top-0"
                  :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <SchedaAbbonamento v-if="dialogAbbonamento && abbonamentoSelected" :abbonamentoSelected="abbonamentoSelected" @chiudiDialogScheda="chiudiDialogScheda" />
        </v-dialog>
    </v-container>
</template>

<script>
import apiAbbonamenti from "@/js/pages/abbonamenti";
import {unixToItalian} from "@/js/formatDate";
import SchedaAbbonamento from "@/components/SchedaAbbonamento.vue";

export default {
    name: "listAbbonamenti",
    components: {
        SchedaAbbonamento
    },
    data() {
        return {
            abbonamenti: [],
            token: this.$cookies.get("tokengestionalestorello"),
            search: "",
            page: 1,
            itemsPerPage: 10,
            headers: [
                {
                    text: "Nome",
                    align: "start",
                    sortable: true,
                    value: "rUtente.nome",
                },
                { text: "RSA", value: "rUtente.rRSA.nome" },
                { text: "Stato", value: "status" },
                { text: "Totale", value: "amount_total" },
                { text: "Stato Pagamento", value: "payment_status" },
                { text: "Termine", value: "cancel_at" },
                { text: "Periodo Corrente", value: "current_period_start" },
                { text: "Data Creazione", value: "created" },
                { text: "Tipo Abbonamento", value: "tipo_abbonamento" },
            ],
            loadingData: false,
            dialogAbbonamento: false,
            abbonamentoSelected: undefined,
            //sortare per data current_period_start
            //periodo corrente current_period_start
            //termin cancel_at
            //nuovo utente se current_period_start == data_creazione
        };
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.abbonamenti.length / this.itemsPerPage);
        },
    },
    async created() {
        await this.reloadLista();
    },
    methods: {
        async reloadLista() {
            this.loadingData = true;
            this.abbonamenti = await apiAbbonamenti.getAll(this.token);
            this.loadingData = false;
        },
        apriAbbonamento(abbonamento) {
            this.abbonamentoSelected = abbonamento;
            this.dialogAbbonamento = true;
        },
        async esporta() {
            if(!this.loadingData) {
                this.loadingData = true;
                let finalUrl = process.env.VUE_APP_ENDPOINT_API + '/csv_export/';
                let nomeFile = await apiAbbonamenti.esportaCSV(this.token);
                if(nomeFile)
                    window.open(finalUrl + nomeFile)
                this.loadingData = false;
            }
        },
        italianFormat(value = 0) {
            return String(parseFloat(value).toFixed(2)).split(".").join(".");
        },
        formatDate(data) {
            if(data) return unixToItalian(data);
            else return '';
        },
        chiudiDialogScheda() {
            this.dialogAbbonamento = false;
        }
    },
};
</script>